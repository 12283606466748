html, body {
  min-height: 100%;
  margin: 0;
  background-color: #ddd;
  margin:0;
}

@media only screen and (max-width: 1000px) { /* Smartphone */
  body {
    font-size: 18px;
  }

  button {
    font-size: 18px;
    border-radius: 8px;
    padding: 6px 6px;
  }
}
@media only screen and (min-width: 1001px) { /* PC */
  body {
    font-size: 18px;
  }

  button {
    font-size: 28px;
    border-radius: 6px;
    padding: 8px 20px;
  }
}

button {
  background-color: #5FBD63;
  color: black;
  border: none;
}

.buttonTable {
  width: 80%;
  margin-left: auto;
  margin-right: auto;
}

.buttonTable td {
  width: 50%;
}

.buttonTilesetify {
  width: 100%;
}

.button:hover {
  background-color: #31B636;
  color: white;

	-webkit-transition: .1s ease-in-out;
	transition: .1s ease-in-out;
}
.vertical {
  width: 80%;
}
.horizontal {
  width: 80%;
}

.pButton {
  text-align: justify;
}

.textaligncenter {
  text-align: center;
}

.tilesetifyCenter {
  width:70%;
  margin:auto;
}
a {
  text-decoration: none;
}
a:link {
  color: #1E25FF;
}
a:visited {
  color: #1E25FF;
}
a:hover {
  color: #9700FF;
}
a:active {
  color: #A0FF95;
}

a.credits:link {
  color: #BCC5FF;
}
a.credits:visited {
  color: #BCC5FF;
}
a.credits:hover {
  color: #E4BCFF;
}
a.credits:active  {
  color: #BCC5FF;
}

.screens {
  color: #fff;
}
.screens:visited {
  color: #fff;
}
.screens:hover {
  color: #999;

	-webkit-transition: .3s ease-in-out;
	transition: .3s ease-in-out;
}
.screens:active  {
  color: #fff;
}

/* Add a black background color to the top navigation */
.topnav {
  overflow: hidden;
  background-color: #333;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 999;
}

/* Style the links inside the navigation bar */
.topnavlinks {
  float: left;
  color: #f2f2f2;
  text-align: center;
  text-decoration: none;
}

/* Change the color of links */
.topnavlinks:hover {
  background-color: #ddd;
  color: black;

	-webkit-transition: .3s ease-in-out;
	transition: .3s ease-in-out;
}

/* Add a color to the active/current link */
.topnavlinks.active {
  background-color: #04AA6D;
  color: white;
}

.footer, .push {
  height: 50px;
}
.footer {
  background-color: #333;
  width: auto;
  color: #fff;
  font-size: 17px;

  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.projectLinksTable {
  margin: 0 auto;
  width: 90%;
  max-height: 400px;
  /*border: 1px solid;*/
  background-color: #333;
  border-radius: 8px;
  font-size: 30px;
}

.projectLinksTable td {
  padding: 0.5em;
  padding: 1.5%;
  text-align: center;
}

.projectLinksIMGS {
  width: 100%;
}

@media only screen and (max-width: 1000px) { /* Smartphone */
  .projectLinksTable td {
    display: block;
    width: 90%;
    margin: 0 auto;
  }

  .projectLinksIMGShalfsize {
    width: 100%;
  }

  .screenIMG {
    width: 95%;
  }

  .logo {
    max-width: 30%;
  }

  .title {
    font-size: 40px;
  }

  .mainlogo {
    width: 100%;
  }

  .topnavlinks {
    font-size: 20px;
    padding: 8px 10px;
  }
}

@media only screen and (min-width: 1001px) { /* PC */
  .projectLinksTable td {
    width: 50%;
  }

  .projectLinksIMGShalfsize {
    width: 50%;
  }

  .screenIMG {
    width: 70%;
  }

  .logo {
    max-width: 12%;
  }

  .title {
    font-size: 48px;
  }

  .mainlogo {
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: 60%;
  }

  .topnavlinks {
    font-size: 24px;
    padding: 12px 14px;
  }
}














#App {
  font-family: sans-serif;

  /* Give app full page to work with */
  height: 100vh;
}

#page-wrap {
  text-align: center;

  /* Prevent sidebar from showing a scrollbar on page */
  overflow: auto;
}

/* Individual item */
.bm-item {
  display: inline-block;

  /* Our sidebar item styling */
  text-decoration: none;
  margin-bottom: 10px;
  color: #d1d1d1;
  transition: color 0.2s;
}

/* Change color on hover */
.bm-item:hover {
  color: white;

	-webkit-transition: .3s ease-in-out;
	transition: .3s ease-in-out;
}

/* The rest copied directly from react-burger-menu docs */

/* Position and sizing of burger button */
.bm-burger-button {
  position: fixed;
  width: 36px;
  height: 30px;
  left: 10px;
  top: 10px;
}

/* Color/shape of burger icon bars */
.bm-burger-bars {
  background: #ddd;
}

/* Position and sizing of clickable cross button */
.bm-cross-button {
  height: 24px;
  width: 24px;
}

/* Color/shape of close button cross */
.bm-cross {
  background: #bdc3c7;
}

/* General sidebar styles */
.bm-menu {
  background: #373a47;
  padding: 2.5em 1.5em 0;
  font-size: 1.15em;
}

/* Morph shape necessary with bubble or elastic */
.bm-morph-shape {
  fill: #373a47;
}

/* Wrapper for item list */
.bm-item-list {
  color: #b8b7ad;
}

/* Styling of overlay */
.bm-overlay {
  background: rgba(0, 0, 0, 0.3);
}











/* Opacity #1 */
.hoverimg img {
	opacity: 1;
}
.hoverimg:hover img {
	opacity: .5;

	-webkit-transition: .3s ease-in-out;
	transition: .3s ease-in-out;
}
